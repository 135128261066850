import React, { useEffect, useState } from "react";
import "./Add_Fund.css";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { updateAuth } from "../../Redux/AuthSlice";
import Web3 from "web3";
import fetch from "node-fetch";

import {
  Abi_StakingMain,
  Abi_StakingUsdt,
  Contract_StakingMain,
  Contract_StakingUsdt,
} from "../../Utils/ContractAddress";

import { Spinner } from "react-bootstrap";

const Activate_Staking = () => {
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);
  let [Token_Bal, setToken_Bal] = useState(0);
  const [IsUpgrade, setIsUpgrade] = useState(0);
  const [PhaseRate, setPhaseRate] = useState(0);
  const [PhaseName, setPhaseName] = useState("");
  const [USDTValue, setUSDTValue] = useState(0);
  const [TokenValue, setTokenValue] = useState(0);
  const [PlanId, setPlanId] = useState(0);
  // const [TokenAmount, setTokenAmount] = useState(0);

  const [Wallet_Address, setWallet_Address] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const CalculateValue = (val) => {
    const Addvalues = val;
    const Totalvalue = parseFloat(Addvalues) + parseFloat(USDTValue);
    const TokenAmount = parseFloat(Totalvalue) / parseFloat(PhaseRate);
    setUSDTValue(Totalvalue);
    setTokenValue(TokenAmount);
  };

  const handleReset = () => {
    setUSDTValue(0);
    setTokenValue(0);
    //setTokenAmount(0);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      });
      responce = responce.data.data[0][0];

      setPhaseRate(responce.StakingPhaseRate);
      setPhaseName(responce.StakingPhaseName);
      setIsUpgrade(responce.CurrentPackage);
      setWallet_Address(responce.btcaddress);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_StakingUsdt,
        Contract_StakingUsdt
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setToken_Bal(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  /*
  const TokenBalance = async () => {
    try {

      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_Token,
        ContractAddress_Token
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      console.log("Result ", result.toString());
      result = parseFloat(result) / parseFloat(1000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setToken_Bal(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };*/

  /*
  const Getprice = async (usdAmount) => {
    try {
      let Token_rate = 0;

      let contract = await new window.web3.eth.Contract(
        Abi_Main,
        ContractAddress_Main
      );

      let usdvalue = parseInt(usdAmount) * 100000000;
      //console.log("usdvalue", usdvalue.toString());
      let data = await contract.methods.getPrice(usdvalue.toString()).call();
      //console.log("Getprice", data.toString());

      Token_rate = data.toString() / 1000000000;
      Token_rate = parseFloat(Token_rate);

      setAmount(usdAmount);
      setTokenAmount(Token_rate);
    } catch (error) {
      console.log("Error while Getprice", error);
    }
  };
  */

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  // const web3 = new Web3(
  //   new Web3.providers.HttpProvider(
  //     "https://polygon-mainnet.infura.io/v3/045e3d0386824476a6198431b88d7b93"
  //     // "https://polygon-mainnet.infura.io/v3/919cffea35394cf3bb07927396e6850f"
  //   )
  // );

  // // Function to fetch gas fees from Polygonscan
  // const fetchGasFees = async () => {
  //   const response = await fetch(
  //     "https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=9D2SQZ961EWHQ4B5EKU86GEG4SZCF29D2A"
  //     // "https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=7Q5D837CUDMBZD88P4C87X4S9ISEKRHXEP"
  //   );
  //   const data = await response.json();
  //   console.log(data, "data");

  //   const { SafeGasPrice, ProposeGasPrice, FastGasPrice } = data.result;

  //   // Choose the appropriate gas price based on your needs (e.g., SafeGasPrice, ProposeGasPrice, FastGasPrice)
  //   const priorityFeePerGas = web3.utils.toWei(FastGasPrice, "gwei"); // Example using FastGasPrice
  //   console.log(priorityFeePerGas, "priorityFeePerGas");
  //   return {
  //     baseFeePerGas: web3.utils.toWei(SafeGasPrice, "gwei"),
  //     priorityFeePerGas: priorityFeePerGas,
  //   };
  // };

  // Function to activate the transaction
  const Activate = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (USDTValue !== 0) {
          if (parseFloat(Token_Bal) < parseFloat(USDTValue)) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          }else  if (parseFloat(USDTValue) < parseFloat(10)) {
            toast.error("Minimum token value  of 10 USDT can be Staked!!");
            setloading(false);
          }else  if (parseInt(PlanId) ===0) {
            toast.error("Select Staking Duration!!");
            setloading(false);
          }  else {
            let amountValue = Web3.utils.toWei(USDTValue, "ether").toString();
            console.log("amountValue-->", amountValue);

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              // const newWeb3 = new Web3(window.ethereum);
              let contract_Main = await new window.web3.eth.Contract(
                Abi_StakingMain,
                Contract_StakingMain
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_StakingUsdt,
                Contract_StakingUsdt
              );

              let approveCall = await contract_USDT.methods
                .approve(Contract_StakingMain, amountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("Approved Succesful");

              let sellCall = await contract_Main.methods
                .deposit(amountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });

              // Post transaction details to API
              let res = await API.post(
                "StakingActivation",
                {
                  uid: user,
                  PlanId: PlanId,
                  walletAddress: acc,
                  usd: USDTValue,
                  token: TokenValue,
                  bnb: 0,
                  txn: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("activation", res.data);
              toast.success("Transaction Successful");
              if (res.data.success === true) {
                toast.success("Staking is under process");
                navigate("/dashboard");
              } else {
                toast.error(res.data.Result);
              }
            } catch (err) {
              console.log("error while calling function sell", err);
              if (
                err.response &&
                err.response.data &&
                err.response.data.showableMessage ===
                  "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    isAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              } else {
                toast.error("Transaction failed: " + (err.message || err));
              }
            }
          }
        } else {
          toast.error("Please Enter Amount First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  useEffect(() => {
    // walletConnected();
    // TokenBalance();
    GetUserDetails();

    // Set an interval to check the wallet connection every 5 seconds
    setInterval(walletConnected, 5000); // 5000 milliseconds = 5 seconds
    setInterval(USDTBalance, 5000); // 5000 milliseconds = 5 seconds
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Staking Activate/Upgrade
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">
                    Staking Activate/Upgrade
                  </div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Staking Activate/Upgrade
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                      Available USDT : {Token_Bal} USDT
                    </h4>
                  </div>
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                      Current Phase : {PhaseName}
                    </h4>
                  </div>
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                    Current rate : 1 Token = {PhaseRate} USDT
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                      <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Staking Duration</label>
                          </div>
                          <div className="col-sm-8 col-6">
                          <select
                          className="form-control tra"
                          id="__BVID__17"
                          value={PlanId}
                          onChange={(e) => setPlanId(e.target.value)}
                        >
                          <option className="br" value="0" selected>Select Staking Duration</option>
                          <option className="br" value="1">3 Months (6.0% Monthly)</option>
                          <option className="br" value="2">6 Months (7.5% Monthly)</option>
                          <option className="br" value="3">9 Months (9.0% Monthly)</option>
                          <option className="br" value="4">12 Months (12.0% Monthly)</option>
                          <option className="br" value="5">18 Months (15.0% Monthly)</option>
                          <option className="br" value="6">36 Months (18.0% Monthly)</option>
                        </select>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Token Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={TokenValue}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>USDT Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control mb-20 shadow-none"
                              id="amountInput"
                              defaultValue={0}
                              value={USDTValue}
                              readOnly
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div
                            className="col-12 mt-3 mb-3 text-center"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-evenly",
                              gap: "10px",
                            }}
                          >
                              <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(5)}
                            >
                              $ 5
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(10)}
                            >
                              $ 10
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(50)}
                            >
                              $ 50
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(100)}
                            >
                              $ 100
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(500)}
                            >
                              $ 500
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(1000)}
                            >
                              $ 1000
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(2500)}
                            >
                              $ 2500
                            </div>{" "}
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => CalculateValue(5000)}
                            >
                              $ 5000
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 restart"
                              onClick={() => handleReset()}
                            >
                              Reset
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-6 row align-items-center justify-content-center">
                            {loading == true ? (
                              <>
                                <Loader />
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                                  onClick={() => Activate()}
                                >
                                  Stake
                                </button>
                              </>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Staking Amount Should Be in multiple of $ 1 and Minimum $ 10.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Activate_Staking;
