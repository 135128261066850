import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utils/API";
import { updateAuth } from "../../Redux/AuthSlice";
import { loadWeb3 } from "../../Utils/contract";
import { toast } from "react-toastify";

const Staking_History = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Wallet_Address, setWallet_Address] = useState("");
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");
  let [connected, setconnected] = useState("Wallet is locked");
  let [spiner, setspiner] = useState(false);

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const FundHistory_API = async () => {
    try {
      let responce = await API.post(
        "StakingHistory",
        {
          uid: user,
          fdate: "",
          tdate: "",
          type: 1,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      // console.log("res-->",responce)
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setreferralApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          Amount: `$ ${item.Amount}`,
          dd: item.dd,
          txnid: item.pinnumber,
          remark: item.PlanName,
          token: item.tokenvalue,
          type: item.type,
          ClaimDate: item.ClaimDate1,
          TotalClaimValue: item.TotalClaimValue,
          transactionHash: (
            <a
              href={`https://bscscan.com/tx/${item.pinnumber}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#ffff" }}
            >
              View Txn
            </a>
          ),
          claim:
            item.ClaimStatus === 0 ? (
              item.IsClaimable === 1 ? (
                <button
                  type="button"
                  className="btn btn-Green py-2 px-3 paymentadd"
                  onClick={(e) => {
                    ClaimStakeTokenAPI(
                      item.IsClaimable,
                      item.id,
                      item.TotalClaimValue
                    );
                  }}
                >
                  Claim Now
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-Green py-2 px-3 paymentadd"
                >
                  Can Not Claim Yet
                </button>
              )
            ) : (
              <button
                type="button"
                className="btn btn-Green py-2 px-3 paymentadd"
              >
                Already Claimed<br/>{item.ClaimedDate1}
              </button>
            ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      // console.log("Something Error", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDetails?uid=${user}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];

      
      setWallet_Address(responce.btcaddress);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const ClaimStakeTokenAPI = async (IsClaimable, Rowid, Amount) => {
    try {
      if (user === undefined || user === "" || user === null) {
        navigate("/");
      }
      // if (IsClaimable === false) {
      //   toast.error("Can Not Claim!!!");
      //   return;
      // }
      let acc = await loadWeb3();
      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        return;
      } else if (acc === "Wrong Network") {
        toast.error("Wrong Network Please Connect BSC Scan Network");
      } else {
        
        // if (Wallet_Address.toUpperCase() === acc.toUpperCase()) {
          if (1>0) {
          if (Amount !== 0) {
            let res = await API.post(
              "/StakeWithdrawal",
              {
                uid: user,
                TokenAmount: Amount,
                RowId: Rowid,
              },
              {
                headers: {
                  Authorization: `${token}`,
                  "Content-Type": "application/json",
                },
              }
            );

            // console.log("res-->", res);
            if (res.data.data === "SUCCESSFUL") {
              toast.success("Claim Request Successful !!!");
              GetUserDetails();
              FundHistory_API();
              setspiner(false);
            } else {
              toast.error(res.data.data);
              setspiner(false);
            }

            setspiner(false);
          } else {
            toast.error("Claimable Token Amount can not be 0!!!");
          }
        } else {
          toast.error("Invalid Wallet address!!!");
        }
      }
    } catch (e) {
      // console.log("Something Error", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  const WalletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc === "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  useEffect(() => {
    WalletConnected();

    FundHistory_API();
    GetUserDetails();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Stake Date", accessor: "dd" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Token Amount", accessor: "token" },
      { Header: "Type", accessor: "type" },
      { Header: "Txn", accessor: "transactionHash" },
      { Header: "Stake For", accessor: "remark" },
      { Header: "Claim Date", accessor: "ClaimDate" },
      { Header: "Claim Value", accessor: "TotalClaimValue" },
      { Header: "Claim Now", accessor: "claim" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper px-0">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold"> Staking History</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3"> Staking History</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Staking History
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Staking_History;
